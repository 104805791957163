import React from 'react'
import { Link } from 'react-router-dom'
import fimg1 from '../../images/features/img-1.png'
import fimg2 from '../../images/features/img-2.png'
import fimg3 from '../../images/features/img-3.png'
import { RiNftFill } from "react-icons/ri";
import { SiBlockchaindotcom } from "react-icons/si";
import { GrVirtualMachine } from "react-icons/gr";


import './style.css'

const Features = (props) => {

    const feature = [
        {
            cIcon: 'fi flaticon-startup',
            cImg: <GrVirtualMachine color='#066dff' size={80}></GrVirtualMachine>,
            heading: "On-chain Machine Learning",
            pra: "On-chain machine learning refers to the process of training and deploying machine learning models directly on a blockchain network, enabling decentralized and transparent AI applications.",
        },
        {
            cIcon: 'fi flaticon-label',
            cImg: <SiBlockchaindotcom color='#066dff' size={80}></SiBlockchaindotcom>,
            heading: "On-chain devrivates",
            pra: "On-chain derivatives are financial contracts that are executed and settled directly on a blockchain network. This enables transparent and secure trading of assets without the need for intermediaries.",
            cl: 'wpo-features-item-2'
        },
        {
            cIcon: 'fi flaticon-support',
            cImg:<RiNftFill color='#066dff' size={80}></RiNftFill>,
            heading: "AIGC-NFTs",
            pra: "AIGC-NFTs, or Artificially Intelligent Generated Content Non-Fungible Tokens, are unique digital assets representing AI-generated content, offering new opportunities for ownership, authenticity, and provenance in the digital space.",
        },
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <div className={props.fClass} style={{backgroundColor: "white"}}>
            <div className="container">
                <div className={props.bundle}>
                    <div className="section-title">
                        <h2>Use Cases</h2>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> */}
                    </div>
                    <div className="row">
                        {feature.map((ftr, fitem) => (
                            <div className="col-lg-4 col-md-6 custom-grid col-12" key={fitem}>
                                <div className={`wpo-features-item ${ftr.cl}`}>
                                    <div className="wpo-features-single">
                                        <div className="wpo-features-icon">
                                            {ftr.cImg}
                                        </div>
                                        <div className="wpo-features-text">
                                            <h2>{ftr.heading}</h2>
                                            <p>{ftr.pra}</p>
                                            {/* <Link onClick={ClickHandler} to="/service-single">See More...</Link> */}
                                        </div>
                                    </div>
                                    {/* <div className="wpo-features-img">
                                        {ftr.cImg}
                                    </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;
