import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar'
import MobileMenu from '../MobileMenu'
import './style.css'
// import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const Header = (props) => {
    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    // const route = useRouteMatch()
    const [currentRoute, setCurrentRoute] = useState('/home')

    // useEffect(() => {
    //     setCurrentRoute(route.path)
    // }, [route])

    return (
        <div className={`middle-header ${props.headerClass}`}>
            <HeaderTopbar />
            <div className="header-style-1">
                <div className="container">
                    <div className="header-content">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                <div className="logo">
                                    <Link onClick={ClickHandler} to="/home" >zkOracle</Link>
                                </div>
                            </div>
                            <div className="col-lg-8 d-lg-block d-none">
                                <nav>
                                    <ul>
                                        <li><Link onClick={ClickHandler} className={currentRoute === '/home' || currentRoute === '/' ? "active" : ''} to="/home" >Home</Link>

                                            {/* <ul>
                                            <li><Link onClick={ClickHandler} className="active" to="/home">Home style 1</Link></li>
                                            <li><Link onClick={ClickHandler} to="/home2">Home style 2</Link></li>
                                            <li><Link onClick={ClickHandler} to="/home3">Home style 3</Link></li>
                                        </ul> */}
                                        </li>
                                        <li><Link onClick={ClickHandler} className={currentRoute === '/app/cases' || currentRoute === '/' ? "active" : ''} to="/app/cases" >Use cases</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact" className={currentRoute === '/contact' ? "active" : ''} >Contact</Link></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-lg-1 col-md-6 col-sm-6 col-6">
                                <div className="contact">
                                    <div className="cart-search-contact">
                                        <div className="header-search-form-wrapper">
                                            <button className="search-toggle-btn"><i className="fi flaticon-search"></i></button>
                                            <div className="header-search-form">
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control" placeholder="Search here..." />
                                                        <button type="submit"><i className="ti-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        {/* <div className="mini-cart">
                                        <button className="cart-toggle-btn"> <i className="fi flaticon-bag"></i> <span className="cart-count">02</span></button>
                                        <div className="mini-cart-content">
                                            <div className="mini-cart-items">
                                                <div className="mini-cart-item clearfix">
                                                    <div className="mini-cart-item-image">
                                                        <Link onClick={ClickHandler} to="/shop"><img src={min1} alt=""/></Link>
                                                    </div>
                                                    <div className="mini-cart-item-des">
                                                        <Link onClick={ClickHandler} to="/shop">Hoodi with zipper</Link>
                                                        <span className="mini-cart-item-price">$20.15</span>
                                                        <span className="mini-cart-item-quantity">x 1</span>
                                                    </div>
                                                </div>
                                                <div className="mini-cart-item clearfix">
                                                    <div className="mini-cart-item-image">
                                                        <Link onClick={ClickHandler} to="/shop"><img src={min2} alt=""/></Link>
                                                    </div>
                                                    <div className="mini-cart-item-des">
                                                        <Link onClick={ClickHandler} to="/shop">Ninja T-shirt</Link>
                                                        <span className="mini-cart-item-price">$13.25</span>
                                                        <span className="mini-cart-item-quantity">x 2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mini-cart-action clearfix">
                                                <span className="mini-checkout-price">$215.14</span>
                                                <Link onClick={ClickHandler} to="/shop" className="view-cart-btn theme-btn">View Cart</Link>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-2">
                                <MobileMenu />
                            </div>
                        </div>

                        <div className="clearfix"></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Header;
