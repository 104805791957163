/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import {BountyProps} from "./graphStudio";
import {types} from "../zkGraphs";

type DataSource = {
    kind: string;
    network: string;
    event: Array<{address: string | number; events: Array<string>}>
};

type yamlMappingProps = {
        language: string;
        file: string;
        handler: string;
}

type DataDestination = {
    kind: string;
    network: string;
    address: string | number;
};

export type YamlProps = {
    name: string;
    apiVersion: string;
    specVersion: string;
    description: string;
    repository: string;
    dataSources: Array<DataSource>;
    dataDestinations: Array<DataDestination>;
    mapping: yamlMappingProps
};

export type ZKGraphProps = {
    destAddress: string,
    graphDeployedContractAddress: string,
    pinataUrl: string,
    bounty:BountyProps,
    creatorAddress: string,
    graphAddress: string,
    graphYaml: YamlProps,
}

export type GraphAddressProps = {
    [graphAddress: string]: ZKGraphProps;
};

export type ZKGraphsProps = {
    [userAddress: string]: GraphAddressProps;
};


/***********************************************************************************************************************
 * 													STATE 														   *
 * *********************************************************************************************************************/
const initialState: ZKGraphsProps = {
    // userAddress: {
    //     graphAddress: {
    //         destAddress: "exampleDestAddress",
    //         graphDeployedContractAddress: "exampleGraphDeployedContractAddress",
    //         pinataUrl: "examplePinataUrl",
    //         bounty: {
    //             rewardPerTrigger: 0,
    //         },
    //     },
    // },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.GRAPH_STUDIO_ALL_ZKG.success():
            return {...action.response.zkGraphs};
        case types.GRAPH_STUDIO_ZKG.success():
            if(action.response.zkg){
                return {...action.response.zkg};
            }
            return {...state}
        default:
            return {...state};
    }
}

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getZKGraphs = (state:any):ZKGraphsProps => state.entities.zkGraphs;

export const getZKGraphByUserAddress = (state:any, userAddress:string): Array<ZKGraphProps> => {
    console.log(state, userAddress)

    if(!state.zkGraphs.allUserAddress.includes(userAddress)) return [];

    return Object.keys(state.entities.zkGraphs[userAddress]).map((graphAddress:string) => {return state.entities.zkGraphs[userAddress][graphAddress]});
};

export const getMyZkGraphByGraphAddress = (state:any): ZKGraphProps => {
    const graphs:ZKGraphsProps = state.entities.zkGraphs;
    const activeZkg = state.zkGraphs.activeZkg;
    console.log(activeZkg)
    return activeZkg.creatorAddress &&  activeZkg.graphAddress  && graphs[activeZkg.creatorAddress][activeZkg.graphAddress];
}
