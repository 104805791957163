import {types} from "../asEditor";
import {hexToUint8Array, types as pocTypes} from "../poc";
import React from "react";
import codes from "../../../components/ASEditor/web.json"
import createTreeFromPaths from "../../../components/ASEditor/utils/createTreeFromPaths";
/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
export const LOGS = {
    prefix: "@hyperoracle.io $",
    setup: {
        request: "Setting up image...",
        failed: "Setup failed",
        success: "Setup done",
    },
    execute:{
        request: "zkgraph exec",
        failed: "Execute failed",
        success: "Execute done",
    },
    compile: {
        request: "Compiling...",
        failed: "Compile failed",
        success: "Compile done",
    },
    prove: {
        request: "Proving...",
        failed: "Prove failed",
        success: "Prove done",
    },
    upload: {
        request: "Uploading...",
        failed: "Upload failed",
        success: "Upload done",
    },
    verify: {
        request: "Verifying...",
        failed: "Verify failed",
        success: "Verify done",
    },
    publish: {
        request: "Publishing...",
        failed: "Publish failed",
        success: "Publish done",
    },
    saveFiles: {
        request: "Saving files",
        failed: "Save files failed",
        success: "Save files done",
    },
    retrieveFiles: {
        request: "Retrieving files",
        failed: "Retrieve files failed",
        success: "Retrieve files done",
    }
}

const initialState = {
    files: createTreeFromPaths(codes),
    ...codes,
    compile: "",
    moduleWasm: null,
    logs: [{content: "Welcome to HyperOracle.", prefix: LOGS.prefix}],
}

export const schema = {
    name: "asEditor",
    id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RETRIEVE_FILES.success():
            return {...state, ...action.payload}
        case types.RESET_FILE:
            return {...state, [action.payload]: codes[action.payload]}
        case pocTypes.CLEAR_STUDIO_SETUP_STATE_PROVE:
            return {...state, "src/zkgraph.yaml":  codes["src/zkgraph.yaml"]}
        case pocTypes.CONFIG_POC_INFO:
            return {...state, "src/zkgraph.yaml":  action.response.yaml}
        case types.ASEDITOR_CODE:
            console.log(action.payload)
            switch (action.payload.asEditorCurrentTab.id){
                case "src/zkgraph.yaml":
                case "src/mapping.ts":
                    return {...state, [action.payload.asEditorCurrentTab.id]: action.payload.value, compile: state[action.payload.asEditorCurrentTab.id] !== action.payload.value ? "" : state.compile, moduleWasm: state[action.payload.asEditorCurrentTab.id] !== action.payload.value ? null : state.moduleWasm};
                case "compile":
                    return {...state, compile: action.payload.value};
                case "moduleWasm":
                    return {...state, moduleWasm: action.payload.value.toString()};
                default:
                    return {...state}
            }
        case types.ASEDITOR_NEW_FILE:
            return {...state, files: {...state.files, children: [...state.files.children, action.payload] }}
        case types.ASEDITOR_DELETE_FILE:
            return {...state, files: {...state.files, children: [...state.files.children.filter((file) => file.id !== action.payload)]}}
        case pocTypes.CONFIG_MD5:
            let description_url_decoded = decodeURIComponent(action.response.description_url);
            console.log(description_url_decoded)
            return {...state, module: description_url_decoded.split(" ")[1] === "function" ?
                    description_url_decoded[0] ===  '"' ?
                    description_url_decoded.slice(1, -1): description_url_decoded :
                    initialState.module
            }
        case types.ASEDITOR_SENDTOCOMPILE.success():
            return {...state, moduleWasm: hexToUint8Array(action.response.wasmModuleHex), compile: action.response.wasmWat}
        case types.PUSH_LOG:
            return {...state, logs: [...state.logs, action.payload]}
        default:
            return state;
    }
}

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getAsEditor = (state) => {
    return state.entities.asEditor
}

export const getAsEditorLogs = (state) => {
    return state.entities.asEditor.logs
}
