import React, { lazy, Suspense } from "react";
import Home from "./pages/Home";
import LoadingScreen from "./utils/LoadingScreen";
import WagmiProvider from "./contexts/wagmiContext";
import { Paper } from "@mui/material";


const Loadable = (Component:any) => (props:any) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );


const MainLayout =  Loadable(lazy(() => import("./layout")));
const ZKIndexing = Loadable(lazy(()=>import("./pages/About/ZKIndexing")));
const ZKAutomation = Loadable(lazy(()=>import("./pages/About/ZKAutomation")));
const ZKGraph = Loadable(lazy(()=>import("./pages/ZKGraph")));
const AppIndexing = Loadable(lazy(()=>import("./pages/App/Indexing")));
const Cases = Loadable(lazy(()=>import("./pages/App/Cases")));
const Studio = Loadable(lazy(()=>import("./pages/App/Studio")));
const GraphCreation = Loadable(lazy(()=>import("./pages/App/Studio/GraphCreation")));
const CaseSinglePage = Loadable(lazy(()=>import("./pages/App/Cases/caseSinglePage")));

const OpmlSDPage  = Loadable(lazy(()=>import("./pages/App/Opml/SD")))
const OpmlLLMPage  = Loadable(lazy(()=>import("./pages/App/Opml/LLM")))

const ContactPage = Loadable(lazy(()=>import("./pages/Home/ContactPage")));
const routes = [
    {
        path: "about",
        element: <MainLayout />,
        children: [
            {
                path: "indexing",
                element: <ZKIndexing/>
            },
            {
                path: "automation",
                element: <ZKAutomation />,
            },
        ]
    },
    {
        path: "app",
        children: [
            {
                path: "zkGraphStudio/create",
                element:
                    <Paper sx={{height: 'auto', width: 'auto', minHeight:"100%", minWidth:"auto"}} square elevation={0}>
                    <WagmiProvider>
                        <GraphCreation/>
                    </WagmiProvider></Paper>
            }
        ]
    },
    {
      path: "app",
      element: <MainLayout/>,
        children: [
            {
                path: "cases",
                element: <Cases/>,
            },
            {
                path: "cases/:caseName",
                element: <CaseSinglePage/>
            },
            {
                path: "zkGraph",
                element: <ZKGraph/>,
            },
            {
                path: "zkGraph/:protocolType/:protocolId/:addressId",
                element: <AppIndexing/>
            },
            {
                path: "zkGraph/:protocolType/:protocolId/:addressId/:ipfs",
                element: <AppIndexing/>
            },
            {
                path: "zkGraphStudio",
                element: <Studio/>,
            },
            {
                path: "zkGraphStudio/myGraphs",
                element: <Studio/>,
            },
            {
                path: "zkGraphStudio/create",
                element: <GraphCreation/>
            },
            {
                path: "opml/sd",
                element: <OpmlSDPage/>
            },
            {
                path: "opml/llm",
                element: <OpmlLLMPage/>
            }
        ]
    },
    {
        path: "/",
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "home",
                element: <Home />,
            },
            {
                path: "contact",
                element: <ContactPage/>
            }
        ]
    }
]

export default routes;
