import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className={`wpo-site-footer ${props.ftClass}`}>
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-md-6 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                {/* <img src={props.ftLogo} alt="blog"/> */}
                                zkOracle
                            </div>
                            <p>Enabling Decentralized Scaling of Blockchain Computation </p>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-facebook"></i></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-twitter-alt"></i></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-instagram"></i></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i className="ti-google"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col col-lg-2 col-md-6 col-12">
                        <div className="widget link-widget resource-widget">
                            <div className="widget-title">
                                <h3>Resources</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/wordpress">WordPress Hosting</Link></li>
                                <li><Link onClick={ClickHandler} to="/cloud">Cloud Hosting</Link></li>
                                <li><Link onClick={ClickHandler} to="/shared">Shared Hosting</Link></li>
                                <li><Link onClick={ClickHandler} to="/vps">VPS Hosting</Link></li>
                                <li><Link onClick={ClickHandler} to="/dedicated">Dedicated Hosting</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-6 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>
                                <li><Link onClick={ClickHandler} to="/service-single">Service single</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Our Services</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="col col-lg-4 offset-lg-4 col-md-6 col-12">
                        <div className="widget market-widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <p>online store with lots of cool and exclusive wpo-features</p>
                            <div className="contact-ft">
                                <ul style={{padding: '0'}}>
                                    <li><i className="fi flaticon-pin"></i>San Jose, CA, United States</li>
                        
                                    <li><i className="fi flaticon-mail"></i>team@hyperoracle.io</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-xs-12">
                        <p className="copyright">&copy; 2023 zkOracle. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;
