import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../images/service/img-1.png'
import service2 from '../../images/service/img-2.png'
import service3 from '../../images/service/img-3.png'
import service4 from '../../images/service/img-4.png'
import service5 from '../../images/service/img-5.png'
import service6 from '../../images/service/img-6.png'
import './style.css'

const Service = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    const serviceWrap = [
        {
           simg:service1,
           title:"On-chain Machine Learning",
           des:'By integrating LLAMA on-chain, zkOracle enables advanced AI and business information services directly within the blockchain environment. This integration facilitates the use of AI algorithms for analyzing encrypted data, providing insights and solutions while protecting data privacy. Such a system could be pivotal in diverse sectors, offering predictive analytics, automated decision-making, and customized business information services, all while ensuring data security through encryption and zero-knowledge proofs. These services can be implemented through Software as a Service (SaaS) by users and developers.',
           link:"/service-single"
        },
        {
           simg:service2,
           title:"On-chain Financial Products Innovation",
           des:'zkOracle enables the creation of complex financial contracts and instruments on the blockchain, providing real-time financial information such as share price information. The use of data encryption and zero-knowledge proofs ensures the privacy and security of financial transactions, making it ideal for decentralized finance (DeFi) applications where accurate and reliable data is crucial for capital investment, financial analysis, loan resolution, risk management, and liquidity provisioning. These financial tools can be developed and enhanced through Platform as a Service (PaaS).',
           link:"/service-single"
        },
        {
           simg:service3,
           title:"AIGC-NFTs (Artificial Intelligence Generated Content Non-Fungible Tokens)",
           des:'AIGC-NFTs, which are NFTs generated through AI, can be enhanced by zkOracles. ZKoracles provides verified, encrypted data to AI algorithms that generate unique digital assets, ensuring both the uniqueness and authenticity of the NFTs. This process would benefit from the secure and private data input that zkOracles can provide. These NFTs can be further developed through computer programs for remotely searching the contents of computers and computer networks.',
           link:"/service-single"
        },
        {
           simg:service4,
           title:"Unlimited potential of dApp Development",
           des:'For decentralized application (dApp) developers, zkOracle can offer secure and private data feeds, essential for applications that rely on real-world data. This integration can lead to the creation of more complex, secure, and user-friendly dApps, which can operate with sensitive data without compromising privacy. The development of dApps can utilize internet servers to develop any computer programs for remotely connecting computers, and to create and design network information indexes.',
           link:"/service-single"
        },
        // {
        //    simg:service5,
        //    title:"Dedicated Hosting",
        //    des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
        //    link:"/service-single"
        // },
        // {
        //    simg:service6,
        //    title:"Domain Name",
        //    des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
        //    link:"/service-single"
        // },
    ]

    return(
        <div className="wpo-service-area section-padding" style={{backgroundColor: "white"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Usage</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.map((item, srv) => (
                        <div className="col-lg-12 col-md-12 col-12 custom-grid" key={srv}>
                            <div className="wpo-service-item">
                                {/* <div className="wpo-service-icon">
                                    <img src={item.simg} alt="S-icon"/>
                                </div> */}
                                <div className="wpo-service-text">
                                    <h2>{item.title}</h2>
                                    <p>{item.des}</p>
                                    {/* <Link onClick={ClickHandler} to={item.link}>See More...</Link> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Service;
