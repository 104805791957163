import React from 'react'
import { Link } from 'react-router-dom'
import zkOracle from '../../images/zkOracle.png'
import hyperoracle from '../../images/hyperoracle.jpg'

import './style.css'

const Service = (props) => {


    return (
        <div className="wpo-service-area section-padding" style={{backgroundColor: "white"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>zkOracle</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={zkOracle} height={500} />
                    </div>
                </div>
            </div>
            <div className="container section-padding" style={{paddingBottom: 0}}>
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>hyper oracle</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={hyperoracle} height={500} style={{marginLeft: '30px'}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;
