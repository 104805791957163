/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import {actionTypesConstructor} from "../utils";
import {BigNumber, ethers} from "ethers";
import {changeNetwork} from "../utils/wallet_helper";
import {YamlProps, ZKGraphsProps} from "./entities/zkGraphs";
import yaml from "js-yaml";
import {types as walletTypes} from "./wallet";
import {ZkGraphYaml, constants} from "@hyperoracle/zkgraph-api/browser";

export const addressFactory = constants.addressFactory.sepolia;
export const abiFactory = constants.abiFactory

export const types = {
    GRAPH_STUDIO_ALL_ZKG: actionTypesConstructor("APP/GRAPH_STUDIO/ALL_ZKG/REQUEST", "APP/GRAPH_STUDIO/ALL_ZKG/SUCCESS", "APP/GRAPH_STUDIO/ALL_ZKG/FAILURE"),
    GRAPH_STUDIO_ZKG: actionTypesConstructor("APP/GRAPH_STUDIO/ZKG/REQUEST", "APP/GRAPH_STUDIO/ZKG/SUCCESS", "APP/GRAPH_STUDIO/ZKG/FAILURE"),
}


/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
type ZKGraphsModuleProps = {
    isGettingAllZkg: boolean,
    isGettingZkg: boolean,
    allUserAddress: Array<string>,
    allZkGraphAddresses: Array<string>,
    activeZkg: {
        creatorAddress: string | null,
        graphAddress: string | null,
    },
}

const initialState: ZKGraphsModuleProps = {
    isGettingAllZkg: false,
    isGettingZkg: false,
    allUserAddress: [],
    allZkGraphAddresses: [],
    activeZkg: {
        creatorAddress: null,
        graphAddress: null,
    }
}


/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
    fetchAllZKGraphs: ()=>{
        return async (dispatch: any, getState: any) => {

            const desiredNetworkId = getState().entities.poc.configInfo.network;
            try {
                const response = await changeNetwork(desiredNetworkId);
                await dispatch({
                    type: walletTypes.WALLET_CONNECT,
                    payload: {
                        address: response.userAddress,
                        chain: response.chainId
                    }
                })
            } catch (error) {
                console.error('Network change failed:', error);
                return await dispatch(
                    {
                        type: types.GRAPH_STUDIO_ALL_ZKG.failure(),
                        error: error,
                        message: "Network change failed"
                    }
                )
            }

            await dispatch({
                type: types.GRAPH_STUDIO_ALL_ZKG.request()
            });

            // @ts-ignore
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            console.log(signer)
            console.log(addressFactory);
            const contractFactory = new ethers.Contract(addressFactory, abiFactory, provider);
            const allZkgAddr = await contractFactory.getAllZkg();
            console.log("allZkgAddr: ", allZkgAddr)
            const allZkgGraphs = await Promise.all(allZkgAddr.map(async (addr: any)=> await contractFactory.getGraphInfoByAddress(addr)))

            console.log(allZkgGraphs)

            const zkGraphs:ZKGraphsProps = {}

            for (const graph of allZkgGraphs) {
                const i = allZkgGraphs.indexOf(graph);
                const graphAddress = allZkgAddr[i];
                const creator = graph.creator.toLowerCase();
                const zkGraph = zkGraphs[creator];
                console.log(graph)

                let _yaml:YamlProps;

                    try {
                        console.log(`https://ipfs.io/ipfs/${graph.graphURI}/zkgraph.yaml`)
                        const response = await fetch(`https://ipfs.io/ipfs/${graph.graphURI}/zkgraph.yaml`);

                        if(response.status === 200){
                            const folderData = await response.text();
                            console.log(folderData)
                            _yaml = ZkGraphYaml.fromYamlContent(folderData) as YamlProps;
                            console.log( _yaml, creator)
                            if (_yaml){
                                if(!zkGraphs[creator]){
                                    zkGraphs[creator] = {}
                                }
                                zkGraphs[creator][graphAddress.toLowerCase()] = {
                                    bounty: {rewardPerTrigger: graph.bountyReward.toNumber()},
                                    graphDeployedContractAddress: graph.verifier,
                                    destAddress: graph.destAddr,
                                    pinataUrl: graph.graphURI,
                                    creatorAddress: creator,
                                    graphAddress: graphAddress.toLowerCase(),
                                    graphYaml: _yaml
                                }
                            }
                        }

                    } catch (error) {
                        console.error('Error retrieving files from IPFS:', error);
                    }
            }


            console.log(zkGraphs)

            return dispatch({
                type: types.GRAPH_STUDIO_ALL_ZKG.success(),
                response: {
                    zkGraphs,
                    allUserAddress: Object.keys(zkGraphs),
                    allZkGraphAddresses: allZkgAddr
                }
            })

        }
    },
    fetchZkGraph: (zkgAddress: string) =>{
        return async (dispatch: any, getState: any) => {
            const network = getState().entities.poc.configInfo.network;
            const zkGraphs:ZKGraphsProps = getState().entities.zkGraphs;
            const creatorAddress = getState().entities.wallet.address;

            return await changeNetwork(network).then(async ()=>{
                if(!zkGraphs[creatorAddress]){
                    await dispatch({
                        type: types.GRAPH_STUDIO_ZKG.request()
                    });
                    // @ts-ignore
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const contractFactory = new ethers.Contract(addressFactory, abiFactory, provider);
                    const zkg = await contractFactory.getGraphInfoByAddress(zkgAddress);
                    console.log(zkg)
                    const creator = zkg.creator.toLowerCase();
                    let _yaml:YamlProps;

                    try {
                        const response = await fetch(`https://ipfs.io/ipfs/${zkg.graphURI}/zkgraph.yaml`);
                        if(response.status === 200){
                            const folderData = await response.text();
                            _yaml = yaml.load(folderData) as YamlProps;
                            console.log(_yaml)

                            const zkgInfo = {
                                [creator]: {
                                    [zkgAddress.toLowerCase()]: {
                                        bounty: {rewardPerTrigger: zkg.bountyReward.toNumber()},
                                        graphDeployedContractAddress: zkg.verifier,
                                        destAddress: zkg.destAddr,
                                        pinataUrl: zkg.graphURI,
                                        creatorAddress: creator,
                                        graphAddress: zkgAddress.toLowerCase(),
                                        graphYaml: _yaml
                                    }
                                }
                            }
                            console.log("fetchZkInfo: ", zkgInfo)
                            console.log("fetchZkGraph: ", {
                                creatorAddress: creator,
                                graphAddress: zkgAddress
                            });
                            return dispatch({
                                type: types.GRAPH_STUDIO_ZKG.success(),
                                response: {
                                    zkg: zkgInfo,
                                    activeZkg: {
                                        creatorAddress: creator,
                                        graphAddress: zkgAddress
                                    }
                                }
                            });
                        }
                    } catch (error) {
                        console.error('Error retrieving files from IPFS:', error);
                        return dispatch({type: types.GRAPH_STUDIO_ZKG.failure()})
                    }
                }

                return await dispatch({
                    type: types.GRAPH_STUDIO_ZKG.success(),
                    response: {
                        zkg: null,
                        activeZkg: {
                            creatorAddress: creatorAddress,
                            graphAddress: zkgAddress
                        }
                    }
                })
            })
        }
    }
}


/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.GRAPH_STUDIO_ALL_ZKG.request():
            return {...state, isGettingAllZkg: true};
        case types.GRAPH_STUDIO_ALL_ZKG.success():
            return {...state, isGettingAllZkg: false, allUserAddress: action.response.allUserAddress, allZkGraphAddresses: action.response.allZkGraphAddresses};
        case types.GRAPH_STUDIO_ALL_ZKG.failure():
            return {...state, isGettingAllZkg: false};
        case types.GRAPH_STUDIO_ZKG.request():
            return {...state, isGettingZkg: true};
        case types.GRAPH_STUDIO_ZKG.success():
            return {...state, isGettingZkg: false, activeZkg: action.response.activeZkg};
        case types.GRAPH_STUDIO_ZKG.failure():
            return {...state, isGettingZkg: false};
        default:
        return state;
    }
}


export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getZKGraphsModule = (state: any):ZKGraphsModuleProps => state.zkGraphs;
