import { getRandomLLMPrompt, getRandomPrompt } from "../../../utils/opml";
import { types as opmlModulesTypes } from "../opml";
/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
const ROLES = {
    CHALLENGER: "CHALLENGER",
    SUBMITTER: "SUBMITTER"
}

export const types = {
    OPML_FORM_UPDATE: "APP/OPML/FORM/UPDATE",
    OPML_CONFIG_CONTRACT_ADDRESS_UPDATE: "APP/OPML/CONFIG/CONTRACT_ADDRESS/UPDATE",
    OPML_OP_UPDATE: "APP/OPML/OP/UPDATE",
    OPML_LLM_UPDATE: "APP/OPML/LLM/UPDATE",
    OPML_HASH_UPDATE: "APP/OPML/HASH/UPDATE",
    OPML_STATE_RESET: "APP/OPML/STATE/RESET",
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
    config: {
        contractAddress: ""
    },
    posts: [],
    hash: {
        value: '',
        slot: ''
    },
    form: {
        modelName: "StableDiffusion",
        name: "",
        prompt: "",
        photo: "",
    },
    search: {
        searchText: "",
        searchedResults: null,
        searchTimeout: null
    },
    op: {
        assertResult: "",
        turn: ROLES.CHALLENGER,
        checkpoints: {
            challenger: [],
            submitter: ""
        },
        roots: {
            challenger: [],
            submitter: ""
        },
        id: {
            challenger: ""
        }
    },
    llm: {
        output: ""
    }
}

export type OpmlEntityState = typeof initialState

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const actions = {
    // TODO - add action creators
    updateOpmlForm: (data: any) => {
        return async (dispatch: any, getState: any) => {
            await dispatch({
                type: types.OPML_FORM_UPDATE,
                payload: data
            })
        }
    },
    updateContractAddress: (contractAddress: string) => {
        return async (dispatch: any, getState: any) => {
            await dispatch({
                type: types.OPML_CONFIG_CONTRACT_ADDRESS_UPDATE,
                payload: {
                    contractAddress
                }
            })
        }
    },
    updateOpmlOp: (op: typeof initialState['op']) => {
        return async (dispatch: any, getState: any) => {
            await dispatch({
                type: types.OPML_OP_UPDATE,
                payload: op
            })
        }
    },
    randomOpmlPrompt: () => {
        return async (dispatch: any, getState: any) => {
            const prompt = getRandomPrompt(getState().entities.opml.form.prompt)
            await dispatch({
                type: types.OPML_FORM_UPDATE,
                payload: {
                    prompt
                }
            })
        }
    },
    randomLLMPrompt: () => {
        return async (dispatch: any, getState: any) => {
            const prompt = getRandomLLMPrompt(getState().entities.opml.form.prompt)
            await dispatch({
                type: types.OPML_FORM_UPDATE,
                payload: {
                    prompt
                }
            })
        }
    },
    resetOpmlState: () => {
        return async (dispatch: any, getState: any) => {
            await dispatch({
                type: types.OPML_STATE_RESET,
            })
        }
    }
}


/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case opmlModulesTypes.GENERATE_CHALLENGER_ID.success():
            const challengerId = action.payload //TODO: confirm this is the correct payload
            return { ...state, op: { ...state.op, id: { ...state.op.id, challenger: challengerId } } };
        // TODO the rest of the cases
        case types.OPML_FORM_UPDATE:
            const form = action.payload;
            return {
                ...state, form: {
                    ...state.form,
                    ...form
                }
            }
        case types.OPML_CONFIG_CONTRACT_ADDRESS_UPDATE:
            const address = action.payload
            return {
                ...state, config: {
                    ...state.config,
                    contractAddress: address
                }
            }
        case types.OPML_OP_UPDATE:
            const op = action.payload
            return Object.assign({
                ...state,
            }, {
                op: {
                    ...state.op,
                    ...op
                }
            })
        case types.OPML_LLM_UPDATE:
            const llm = action.payload
            return {
                ...state,
                llm: {
                    ...state.llm,
                    ...llm
                }
            }
        case types.OPML_HASH_UPDATE:
            const hash = action.payload
            return {
                ...state,
                hash: {
                    ...state.hash,
                    ...hash
                }
            }
        case types.OPML_STATE_RESET:
            return {
                ...initialState
            }
        default:
            return state;
    }
}

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getOpml = (state: any) => state.entities.opml as typeof initialState;

export default reducer;
