import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

const Hero = (props) => {

    return (
        <section className={props.heroclass}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-md-12">
                        <div className="wpo-hero-text">
                            <div className="wpo-hero-title">
                                <h2>Enabling Decentralized Scaling of<br /><span> Blockchain Computation</span></h2>
                            </div>
                            <div className="wpo-hero-para">
                                <p>Welcome independent contributor and open-source, permissionless projects to come build together! Pls contact team@hyperoracle.io for more info.</p>
                            </div>
                            {/* <div className="btns">
                                <Link to="/contact" className="theme-btn">Get In Touch</Link>
                                <Link to="/about" className="theme-btn-s2">Know More</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-vec">
            </div>
        </section>
    )
}

export default Hero;
