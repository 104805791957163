import React from "react";
import {useTheme} from "@mui/material";
import useSettings from "../../hooks/aboutSettings/useSettings";
import Navbar from "./components/Navbar";
import Hero from "./components/hero";
import Features from "./components/Features";
import ImageSection from "./components/ImageSection";
import Service from "./components/Service";
import Scrollbar from "./components/scrollbar";
import Footer from "./components/footer";
import './App.css';

const Home = () =>{
    const theme = useTheme();
    const {settings} = useSettings();
    return<>
        <Navbar />
        <Hero heroclass={'wpo-hero-style-1'} />
        <Features fClass={'wpo-features-area section-padding'} style={{backgroundColor: "white"}} />
        <ImageSection style={{backgroundColor: "white"}}/>
        <Service style={{backgroundColor: "white"}}/>
        <Footer  />
        <Scrollbar />
    </>

}

export default Home
